import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['lineTotals', 'total', 'currency', 'destroyItem']
  totalTarget: HTMLInputElement
  currencyTarget: HTMLInputElement
  destroyItemTargets: HTMLInputElement[]
  lineTotalsTargets: HTMLInputElement[]

  updateTotal() {
    let index = 0
    const items = this.lineTotalsTargets.filter((target) => {
      const destroyTarget = this.destroyItemTargets.find((target) => target.id === `invoice_invoice_lines_attributes_${index}__destroy`)
      index += 1
      return destroyTarget?.value !== '1'
    })
    const total = items.reduce((accum, target) => accum + Number(target.value), 0)
    const currency = this.currencyTarget.value || 'rub'
    const locale = currency === 'rub' ? 'ru-RU' : 'en'
    if (!isNaN(total)) {
      const priceFormat = new Intl.NumberFormat(locale, {style: 'currency', currency: currency, minimumFractionDigits: 2})
      this.totalTarget.innerHTML = priceFormat.format(total)
    } else {
      this.totalTarget.innerHTML = 'N/A'
    }
  }
}
