import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['amount', 'amountCoins', 'formulas', 'remainResults']
  amountTarget: HTMLInputElement
  amountCoinsTarget: HTMLInputElement
  formulasTarget: HTMLElement
  remainResultsTarget: HTMLElement

  apply() {
    const priceFormat = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 2 })
    const pack = document.getElementById('pack_operation_pack_id') as HTMLSelectElement
    const selectedOption = pack.selectedOptions[0]
    if (!selectedOption.value || !this.amountCoinsTarget.value || !this.amountTarget.value) {
      return;
    }

    const amountEl = document.getElementById('pack_operation_amount') as HTMLInputElement
    const amountCoinsEl = document.getElementById('pack_operation_amount_coins') as HTMLInputElement
    const coinPrice = Number(this.amountTarget.value) / Number(this.amountCoinsTarget.value)
    const amount = coinPrice * Number(selectedOption.dataset.leftCoins)
    amountEl.value = String(parseFloat(String(amount)).toFixed(2))
    amountCoinsEl.value = selectedOption.dataset.leftCoins
    const remain = Number(this.amountTarget.value) - amount
    const remainCoins = Number(this.amountCoinsTarget.value) - Number(selectedOption.dataset.leftCoins)
    localStorage.setItem('calculator_remain', String(remain))
    localStorage.setItem('calculator_remain_coins', String(remainCoins))
    this.formulasTarget.innerHTML = `1 x ${priceFormat.format(Number(this.amountTarget.value))} / ${Number(this.amountCoinsTarget.value)}<br> ${priceFormat.format(coinPrice)} x ${Number(selectedOption.dataset.leftCoins)}`
    this.remainResultsTarget.innerHTML = `Remained amount: <b>${priceFormat.format(remain)}</b> Remained coins: <b>${remainCoins}</b>`
  }
}
