import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['quantity', 'price', 'total', 'totalFormatted', 'currency']

  updateTotal() {
    const total = Number(this.quantityTarget.value) * Number(this.priceTarget.value)
    this.totalTarget.value = total
    const currency = this.currencyTarget.value || 'rub'
    const locale = currency === 'rub' ? 'ru-RU' : 'en'
    if (!isNaN(total)) {
      const priceFormat = new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2 })
      this.totalFormattedTarget.value = priceFormat.format(total)
    } else {
      this.totalFormattedTarget.value = 'N/A'
    }
  }
}
