import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'fee', 'amount', 'amountCoins',
    'packAmount', 'packAmountCoins', 'packCurrency',
    'tradeAmount', 'tradeAmountCoins', 'tradeCurrency',
    'paymentDescription',
    'invoiceInfo', 'invoice'
  ]
  feeTarget: HTMLElement
  amountTarget: HTMLInputElement
  amountCoinsTarget: HTMLInputElement
  packAmountTarget: HTMLInputElement
  packAmountCoinsTarget: HTMLInputElement
  packCurrencyTarget: HTMLInputElement
  tradeAmountTarget: HTMLInputElement
  tradeAmountCoinsTarget: HTMLInputElement
  tradeCurrencyTarget: HTMLInputElement
  paymentDescriptionTarget: HTMLTextAreaElement
  invoiceInfoTarget: HTMLElement
  invoiceTarget: HTMLSelectElement

  connect() {
    const tradeAmount = localStorage.getItem('tradeAmount')
    const tradeAmountCoins = localStorage.getItem('tradeAmountCoins')
    const tradeCurrency = localStorage.getItem('tradeCurrency')
    if (tradeAmount) this.tradeAmountTarget.value = tradeAmount
    if (tradeAmountCoins) this.tradeAmountCoinsTarget.value = tradeAmountCoins
    if (tradeCurrency) this.tradeCurrencyTarget.value = tradeCurrency
    this.calculateAmountCoins()
    this.selectInvoice({ target: this.invoiceTarget })
  }

  updateFee() {
    const percentFormat = new Intl.NumberFormat('ru-RU', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })

    const amountCoins = Number(this.amountCoinsTarget.value)
    const amount = Number(this.amountTarget.value)
    const coinPrice = Number(this.packAmountTarget.value) / Number(this.packAmountCoinsTarget.value)
    if (amountCoins <= 0 || isNaN(amountCoins)
      || amount <= 0 || isNaN(amount)
      || coinPrice <= 0 || isNaN(coinPrice)) {
      this.feeTarget.innerHTML = 'N/A'
      return
    }

    const actualPrice = coinPrice * amountCoins
    const price = amount - actualPrice
    const percent = price / amount
    const currency = this.packCurrencyTarget.value || 'RUB'
    const priceFormat = new Intl.NumberFormat('ru-RU', { style: 'currency', currency, minimumFractionDigits: 2 })
    this.feeTarget.innerHTML = `${priceFormat.format(price)} (${percentFormat.format(percent)})`
  }

  selectPack(event) {
    const option = event.target[event.target.selectedIndex]

    this.packAmountTarget.value = option.dataset.amount
    this.packAmountCoinsTarget.value = option.dataset.amountCoins
    this.packCurrencyTarget.value = option.dataset.currency
  }

  calculateAmountCoins() {
    const amount = Number(this.amountTarget.value)
    const tradeAmountCoins = Number(this.tradeAmountCoinsTarget.value)
    const tradeAmount = Number(this.tradeAmountTarget.value)
    if (isNaN(amount) || amount <= 0) return
    if (isNaN(tradeAmountCoins) || tradeAmountCoins <= 0) return
    if (isNaN(tradeAmount) || tradeAmount <= 0) return

    this.amountCoinsTarget.value = String(amount * tradeAmountCoins / tradeAmount)
  }

  updatePaymentDescription() {
    if (!this.tradeCurrencyTarget.value || !this.amountTarget.value) return

    this.paymentDescriptionTarget.value = `${this.tradeCurrencyTarget.value} ${this.amountTarget.value}`
  }

  selectInvoice(event) {
    const option = event.target[event.target.selectedIndex]

    this.invoiceInfoTarget.innerHTML = `${option.dataset.currency} ${option.dataset.amountDue}`
  }

  saveTrade() {
    const tradeAmountCoins = Number(this.tradeAmountCoinsTarget.value)
    const tradeAmount = Number(this.tradeAmountTarget.value)
    if (!isNaN(tradeAmount) && tradeAmount > 0) localStorage.setItem('tradeAmount', this.tradeAmountTarget.value)
    if (!isNaN(tradeAmountCoins) && tradeAmountCoins > 0) localStorage.setItem('tradeAmountCoins', this.tradeAmountCoinsTarget.value)
    localStorage.setItem('tradeCurrency', this.tradeCurrencyTarget.value)
  }
}
