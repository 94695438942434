import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = { id: String, formId: Number }
  idValue: string;
  formIdValue: number;

  show() {
    const input = document.getElementById(`${this.idValue}-id`) as HTMLInputElement
    input.value = String(this.formIdValue)
    const modal = Modal.getOrCreateInstance(document.getElementById(this.idValue))
    modal.show()
  }
}
