import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  add (e: Event) {
    super.add(e)
    // TODO: focus for the first element description
  }
}
