import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static targets = ['remainOperation', 'remainInfo']
  remainOperationTarget: HTMLElement
  remainInfoTarget: HTMLElement

  connect() {
    const remain = localStorage.getItem('calculator_remain')
    const remainCoins = localStorage.getItem('calculator_remain_coins')
    if (remain && remainCoins) {
      this.remainOperationTarget.dataset.remain = remain
      this.remainOperationTarget.dataset.remainCoins = remainCoins
      this.remainOperationTarget.classList.remove('visually-hidden')
      this.remainInfoTarget.title = `${remain} (${remainCoins})`
      new Tooltip(this.remainInfoTarget.title)
    } else {
      this.remainOperationTarget.classList.add('visually-hidden')
    }
  }

  use() {
    localStorage.removeItem('calculator_remain')
    localStorage.removeItem('calculator_remain_coins')
    this.remainOperationTarget.classList.add('visually-hidden')

    const amountEl = document.getElementById('pack_operation_amount') as HTMLInputElement
    const amountCoinsEl = document.getElementById('pack_operation_amount_coins') as HTMLInputElement
    amountEl.value = String(parseFloat(this.remainOperationTarget.dataset.remain).toFixed(2))
    amountCoinsEl.value = this.remainOperationTarget.dataset.remainCoins
  }
}
